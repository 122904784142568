import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { patchBy, patchObject } from '@/utils';
import type { HttpErrorResponse, TypedResults } from '@/models';
import { initialQueriesState } from './queries.model';

export const queriesSlice = createSlice({
  name: 'queries',
  initialState: initialQueriesState,
  // TODO: Unit test
  reducers: {
    querySuggestionsRequested: (state, _: PayloadAction<string>) =>
      patchBy(state, () => ({ fetchSuggestionsError: undefined })),
    querySuggestionsFetched: (state, { payload }: PayloadAction<TypedResults<string>>) =>
      patchObject(state, 'suggestions', payload),
    querySuggestionsFetchFailed: (state, { payload }: PayloadAction<HttpErrorResponse>) =>
      patchBy(state, () => ({ suggestions: undefined, fetchSuggestionsError: payload })),
  },
});

export const { querySuggestionsRequested, querySuggestionsFetched, querySuggestionsFetchFailed } =
  queriesSlice.actions;
