import { applySgBootstrapTheme, getSgBootstrapTheme, type Theme } from '@/utils';
import { useMemo, useState, type FC, type PropsWithChildren } from 'react';
import { ThemeContext, type ThemeContextValue } from './ThemeContext';

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(getSgBootstrapTheme());

  const value = useMemo<ThemeContextValue>(
    () => ({
      currentTheme: theme,
      setTheme: (theme: Theme) => {
        setTheme(theme);
        applySgBootstrapTheme(theme);
      },
    }),
    [theme],
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};
