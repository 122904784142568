import type { IntlKey } from '@/locale';
import { Button, Icon, type ButtonProps } from '@sgme/ui';
import classNames from 'classnames';
import { useCallback, useRef, useState, type FC, type PropsWithChildren } from 'react';
import { Formatted } from '../Formatted';
import { useOnClickedOutside } from '@/hooks/useOnClickedOutside';
import type { Color } from '@/models';

export type DropdownButtonProps = PropsWithChildren<{
  buttonActiveColor?: Color;
  buttonIcon?: string;
  buttonText: IntlKey;
  dropdownAlignment?: 'start' | 'end';
  hideTextOnMobile?: boolean;
  showToggle?: boolean;
}> &
  Omit<ButtonProps, 'children' | 'onClick'>;

export const DropdownButton: FC<DropdownButtonProps> = ({
  buttonActiveColor,
  buttonIcon,
  buttonText,
  children,
  className,
  color: buttonColor,
  dropdownAlignment = 'start',
  hideTextOnMobile = false,
  showToggle = true,
  ...buttonProps
}) => {
  const [active, setActive] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  useOnClickedOutside(container, () => {
    setActive(false);
  });

  const onToggleButtonClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setActive(v => !v);
    },
    [setActive],
  );

  return (
    <div className={classNames('d-inline-block', className)} ref={container}>
      <Button
        {...buttonProps}
        color={active ? (buttonActiveColor ?? buttonColor) : buttonColor}
        className={classNames({
          'dropdown-toggle': showToggle,
          show: active,
        })}
        onClick={onToggleButtonClicked}
      >
        {buttonIcon && <Icon icon={buttonIcon} />}
        <Formatted
          id={buttonText}
          as="span"
          className={classNames({ 'd-none d-md-inline': hideTextOnMobile })}
        />
      </Button>
      <div
        className={classNames(`dropdown-menu dropdown-menu-${dropdownAlignment}`, { show: active })}
      >
        {children}
      </div>
    </div>
  );
};
