import { SgwtWidgetsContext } from '@/context';
import { ThemeContext } from '@/context/Theme';
import type { SgwtAccountCenterHTMLElement } from '@/types/sgwt-widgets';
import type { Theme } from '@/utils';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

export function AccountCenter(): JSX.Element {
  const intl = useIntl();
  const { setTheme } = useContext(ThemeContext);
  const accountCenter = useRef<SgwtAccountCenterHTMLElement>();

  const sgwtWidgets = useContext(SgwtWidgetsContext);
  useEffect(() => {
    if (!accountCenter.current) {
      return;
    }
    sgwtWidgets.setAccountCenter(accountCenter.current);
  }, [sgwtWidgets, accountCenter]);

  const themeChangedEventHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const newBootstrapTheme: Theme = event.detail.theme === 'dark' ? 'dark' : 'standard';
      setTheme(newBootstrapTheme);
    },
    [setTheme],
  );

  useEffect(() => {
    const ref = accountCenter.current;
    if (ref !== undefined) {
      ref.addEventListener('sgwt-account-center--theme-changed', themeChangedEventHandler);
    }
    return () => {
      if (ref !== undefined) {
        ref.addEventListener('sgwt-account-center--theme-changed', themeChangedEventHandler);
      }
    };
  }, [accountCenter, themeChangedEventHandler]);

  return (
    <sgwt-account-center
      authentication="sg-connect-v2"
      available-languages="fr,en"
      language={intl.locale}
      mode="sg-markets"
      producer-code={import.meta.env.VITE_APP_ID}
      theme-switcher="true"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ref={accountCenter}
    />
  );
}
