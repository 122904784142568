import './App.scss';
import { IntlProvider, SgwtWidgetsProvider, ThemeProvider } from '@/context';
import { Provider } from 'react-redux';
import { appStore } from '@/store';
import { AppRouter } from './layout/AppRouter/AppRouter';

export function App() {
  return (
    <Provider store={appStore}>
      <IntlProvider>
        <ThemeProvider>
          <SgwtWidgetsProvider>
            <AppRouter />
          </SgwtWidgetsProvider>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  );
}
