export const LOCAL_STORAGE_KEYS = {
  Theme: 'APP_THEME',
};

export type LocalStorageKey = (typeof LOCAL_STORAGE_KEYS)[keyof typeof LOCAL_STORAGE_KEYS];

export const saveToLocalStorage = (key: LocalStorageKey, value: string): void => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.setItem(key, value);
  } catch {
    console.error(`Unable to store ${key} into local storage`);
  }
};

export const getFromLocalStorage = (key: LocalStorageKey): string | undefined => {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }
  return localStorage.getItem(key) === 'dark' ? 'dark' : 'standard';
};
