import { saveToLocalStorage, LOCAL_STORAGE_KEYS, getFromLocalStorage } from '../localStorage';

export type Theme = 'dark' | 'standard';
export const DEFAULT_THEME: Theme = 'dark';

const standardStylesheetSelector = 'link#sg-bootstrap-standard';
const standardAgGridStylesheetSelector = 'link#sg-bootstrap-ag-grid-standard';
const darkStylesheetSelector = 'link#sg-bootstrap-dark';
const darkAgGridStylesheetSelector = 'link#sg-bootstrap-ag-grid-dark';

const updateStylesheets = (target: Element, other: Element): void => {
  target.setAttribute('rel', 'stylesheet');
  other.setAttribute('rel', 'preload');
};

const applyDarkTheme = (): void => {
  const targetSgBs = document.querySelector(darkStylesheetSelector);
  const otherSgBs = document.querySelector(standardStylesheetSelector);
  const targetSgBsAgGrid = document.querySelector(darkAgGridStylesheetSelector);
  const otherSgBsAgGrid = document.querySelector(standardAgGridStylesheetSelector);
  if (!targetSgBs || !otherSgBs) {
    return;
  }
  updateStylesheets(targetSgBs, otherSgBs);
  if (!targetSgBsAgGrid || !otherSgBsAgGrid) {
    return;
  }
  updateStylesheets(targetSgBsAgGrid, otherSgBsAgGrid);
};

const applyStandardTheme = (): void => {
  const targetSgBs = document.querySelector(standardStylesheetSelector);
  const otherSgBs = document.querySelector(darkStylesheetSelector);
  const targetSgBsAgGrid = document.querySelector(standardAgGridStylesheetSelector);
  const otherSgBsAgGrid = document.querySelector(darkAgGridStylesheetSelector);
  if (!targetSgBs || !otherSgBs) {
    return;
  }
  updateStylesheets(targetSgBs, otherSgBs);
  if (!targetSgBsAgGrid || !otherSgBsAgGrid) {
    return;
  }
  updateStylesheets(targetSgBsAgGrid, otherSgBsAgGrid);
};

export const applySgBootstrapTheme = (theme: Theme): void => {
  saveThemeToLocalStorage(theme);
  if (theme === 'dark') {
    applyDarkTheme();
  } else {
    applyStandardTheme();
  }
};

export const getSgBootstrapTheme = (): Theme => {
  return getThemeFromLocalStorage() ?? DEFAULT_THEME;
};

export const initializeTheme = (): void => {
  applySgBootstrapTheme(getSgBootstrapTheme());
};

function saveThemeToLocalStorage(theme: Theme) {
  saveToLocalStorage(LOCAL_STORAGE_KEYS.Theme, theme);
}

function getThemeFromLocalStorage(): Theme | undefined {
  const theme = getFromLocalStorage(LOCAL_STORAGE_KEYS.Theme);
  return theme === 'dark' ? 'dark' : 'standard';
}
