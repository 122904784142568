import { getSgBootstrapTheme, type Theme } from '@/utils';
import { createContext } from 'react';

export interface ThemeContextValue {
  currentTheme: Theme;
  setTheme: (theme: Theme) => void;
}

export const ThemeContext = createContext<ThemeContextValue>({
  currentTheme: getSgBootstrapTheme(),
  setTheme: (_: Theme): void => {},
});
